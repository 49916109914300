import React, { useMemo, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AddMaterialModal } from '@pelckmans/business-components/components/add-material-modal';
import { Dialog } from '@pelckmans/business-components/components/dialog';
import { extractLtiDeepLinkInfo } from '@pelckmans/business-components/components/lti-modal/utils/response-decoders';
import { LtiModal } from '@pelckmans/business-components/components/lti-modal';
import { LtiResourceContextProvider } from '@pelckmans/business-components/components/lti-modal/context/LtiResourceContext';
import { useDispatch, useSelector } from 'react-redux';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { tokenGetter } from '../../../../../../services/api';
import { addUserMedia, toggleUserMediaFavorite } from '../../../../../../actions/userMedia';
import { errorOccurred } from '../../../../../../actions/error';
import { getCurrentDigibook } from '../../../../../../selectors/digibooks';
import { getFeatureToggleFor } from '../../../../../../utils/feature-toggle';
import featureToggle from '../../../../../../enums/feature-toggle';
import { getModuleById } from '../../../../../../selectors/module';
import { buildAddMaterialUrl } from '../../../../services/bookwidget-url-builder';
import { LOCATION_ID } from '../../../../../../api/analyticsApi';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../enums/analytics';
import { STUDIO_GROUP } from '../../../../../../constants/constants';

const AddMaterialButton = ({ moduleId, nodeId, showOnlyFavoriteMaterial, openOptionsModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalActive, setIsModalActive] = useState(false);
  const [isLtiModalActive, setIsLtiModalActive] = useState(false);
  const module = useSelector(state => getModuleById(state, moduleId));

  const digibook = useSelector(getCurrentDigibook);
  const hasTeacherDigitalManualLicense = digibook && digibook.teacherDigitalManualLicense;
  const isSharingEnabled = getFeatureToggleFor(featureToggle.SHARING_MATERIAL);
  const isBookwidgetEnabled = getFeatureToggleFor(featureToggle.BOOKWIDGET_USER_MATERIAL);

  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);
  const translations = useMemo(
    () => ({
      title: t('userMaterialModal.title'),
      name: {
        required: t('userMaterialModal.name.validation.required'),
        label: t('userMaterialModal.name.label'),
      },
      url: {
        required: t('userMaterialModal.url.validation.required'),
        invalid: t('userMaterialModal.url.validation.invalid'),
        placeholder: t('userMaterialModal.url.placeholder'),
      },
      file: {
        required: t('userMaterialModal.file.validation.required'),
        notReady: t('userMaterialModal.file.validation.notReady'),
        fileSizeExceeded: t('userMaterialModal.file.validation.fileSizeExceeded'),
        fileTypeNotSupported: t('userMaterialModal.file.validation.fileTypeNotSupported'),
        storageLimitExceeded: t('userMaterialModal.file.validation.storageLimitExceeded'),
      },
      bookwidget: {
        required: t('userMaterialModal.bookwidget.validation.required'),
        actionText: t('userMaterialModal.bookwidget.actionText'),
        isScorableLabel: t('userMaterialModal.bookwidget.isScorableLabel'),
        userMaxScoreLabel: t('userMaterialModal.bookwidget.userMaxScoreLabel'),
        invalidScore: t('userMaterialModal.bookwidget.validation.invalidScore'),
        userMaxScoreRequired: t('userMaterialModal.bookwidget.validation.userMaxScoreRequired'),
      },
      materialSelector: {
        label: t('userMaterialModal.materialSelector.label'),
        urlLabel: t('userMaterialModal.url.label'),
        fileLabel: t('userMaterialModal.file.label'),
        bookwidgetLabel: t('userMaterialModal.bookwidget.label'),
      },
      cancel: t('userMaterialModal.actions.cancel'),
      add: t('userMaterialModal.actions.confirm'),
      upload: t('userMaterialModal.actions.upload'),
      closeDialog: t('userMaterialModal.actions.close'),
      addAndShare: t('userMaterialModal.actions.confirmAndShare'),
    }),
    [t],
  );
  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);

  const handleModalClose = (response, openSharing) => {
    if (response) {
      dispatch(addUserMedia(response));
      if (showOnlyFavoriteMaterial) dispatch(toggleUserMediaFavorite(response));
    }
    setIsModalActive(false);
    if (openSharing) openOptionsModal(response, 'sharing', true);
  };

  return (
    <LtiResourceContextProvider decodeLtiResponseJwt={extractLtiDeepLinkInfo}>
      <div
        className={classNames('pbb-list__link-wrap', {
          'pbb-list__link-wrap--disabled': !hasTeacherDigitalManualLicense,
        })}
      >
        <button
          type="button"
          data-testid="add-material-button"
          disabled={!hasTeacherDigitalManualLicense}
          onClick={() => setIsModalActive(true)}
          className="pbb-list__link"
          title={t('drawer.material.buttons.add_tooltip')}
        >
          <i className="pbb-list__link-icon icon-plus" />
          <span className="pbb-list__link-text">{t('drawer.material.buttons.add')}</span>
        </button>
      </div>
      {isModalActive &&
        createPortal(
          <AddMaterialModal
            tokenAccessor={tokenAccessor}
            moduleId={moduleId}
            nodeId={nodeId}
            onClose={handleModalClose}
            onError={handleError}
            translations={translations}
            closeViaEscape={!isLtiModalActive}
            closeOnClickOutside={!isLtiModalActive}
            zIndex={9999}
            headerIcon={<i role="img" className="icon-bb-material-own" />}
            openLtiModal={() => setIsLtiModalActive(true)}
            canShareMaterial={isSharingEnabled}
            enableBookWidget={isBookwidgetEnabled}
            analyticsData={{
              locationId: LOCATION_ID,
              subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
            }}
            studioGroup={STUDIO_GROUP}
          />,
          document.body,
        )}
      {isLtiModalActive &&
        createPortal(
          <LtiModal
            tokenAccessor={tokenAccessor}
            headerElement={<Dialog.Header title={t('businessComponents.ltiModal.title')} />}
            iframeTitle={t('businessComponents.ltiModal.title')}
            onClose={() => setIsLtiModalActive(false)}
            loginUrl={buildAddMaterialUrl('BookWidgets', module)}
            closeTitle={t('actions.close')}
            studioGroup={STUDIO_GROUP}
          />,
          document.body,
        )}
    </LtiResourceContextProvider>
  );
};

AddMaterialButton.propTypes = {
  nodeId: string.isRequired,
  moduleId: string.isRequired,
  showOnlyFavoriteMaterial: bool.isRequired,
  openOptionsModal: func.isRequired,
};

export default AddMaterialButton;
