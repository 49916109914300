/* eslint-disable no-param-reassign */
import { fabric } from 'fabric';
import Hammer from 'hammerjs';
import shortid from 'shortid';

import { TRANSPARENT } from '../../../constants/constants';
import { annotationTextColors as AnnotationTextColors, annotationBackgroundColors, markerColors, pencilColors } from '../../../enums/colors';
import FabricTypes from '../../../enums/fabrictype';
import FontFamilies, { annotationFonts as AnnotationFonts } from '../../../enums/fontFamilies';
import playerMode from '../../../enums/playerMode';
import POSITIONS from '../../../enums/position';
import { brushSizes as ToolSizes } from '../../../enums/sizes';
import Tools from '../../../enums/tools';
import zoomLevels from '../../../enums/zoomLevel';
import createAnswerStepper from './answer-stepper';
import DrawingBrush from './fabric-custom-pencil-brush';
import EraserBrush from './fabric-eraser-brush';
import createRectWithText from './rect-with-text';

import answerSetNext from '../../../../assets/images/codex-sol-next.svg';
import answerSetIcon from '../../../../assets/images/codex-sol-on.svg';
import answerSetPrev from '../../../../assets/images/codex-sol-previous.svg';

const disableControls = {
  selectable: false,
  hasBorders: false,
  hasControls: false,
  hasRotatingPoint: false,
};

export const X_AXIS_MARGIN = 40;
export const Y_AXIS_MARGIN = 65;
const DRAWER_WIDTH = 340;
const ANNOTATION_PADDING = 20;
const DOCK_HEIGHT = 45; // dock is actually 50px high but we do -5 to render layer underneath the dock

const DRAG_TOOLS = [Tools.ANSWER_REVEAL, Tools.ANSWER_HIDE, Tools.ZOOM_SELECT, Tools.SELECTION_ERASER];

const createDropShadow = (layer, top, left) =>
  new fabric.Rect({
    fill: '#F3F6F7',
    meta: layer,
    top,
    left,
    evented: false,
    shadow: new fabric.Shadow({
      color: 'rgba(94, 128, 191, 1)',
      blur: 12,
      offsetX: 0,
      offsetY: 5,
    }),
    ...disableControls,
  });

fabric.Image.prototype.shouldCache = fabric.Object.prototype.shouldCache;

/**
 * Overriding this method will make sure caches are rendered on whole numbers to avoid blurriness.
 * Without this patch, image caches will become blurry which results in blurry rendering of images on the main canvas.
 * https://github.com/fabricjs/fabric.js/issues/6515
 *
 * We can only apply this in browsers which support getTransform, so for the rest we fall back on default behaviour.
 *
 * @param {CanvasRenderingContext2D} ctx
 */
fabric.Image.prototype.drawCacheOnCanvas = function drawCacheOnCanvas(ctx) {
  if (ctx.getTransform) {
    ctx.imageSmoothingEnabled = this.imageSmoothing;
    ctx.scale(1 / this.zoomX, 1 / this.zoomY);

    const m = ctx.getTransform();
    const mg = [m.a, m.b, m.c, m.d, m.e, m.f];
    const p = fabric.util.transformPoint({ x: -this.cacheTranslationX, y: -this.cacheTranslationY }, mg);
    const finalX = p.x % 1;
    const finalY = p.y % 1;

    /**
     * Store the offset in px of an unzoomed canvas so we can take this into account when erasing.
     */
    this.renderOffsetX = -finalX / this.zoomX;
    this.renderOffsetY = -finalY / this.zoomY;

    ctx.translate(-finalX, -finalY);

    // eslint-disable-next-line no-underscore-dangle
    ctx.drawImage(this._cacheCanvas, -this.cacheTranslationX, -this.cacheTranslationY);
  } else {
    this.callSuper('drawCacheOnCanvas', ctx);
  }
};

export default class FabricService {
  constructor(id, amountOfVisiblePages = 1, pageDimensions, mode = playerMode.BOOK) {
    this.RIGHT_PAGE_OFFSET = 0.5;
    this.fabricCanvas = new fabric.Canvas(id);
    this.currentTool = Tools.POINTER;
    this.dragStartPoint = undefined;

    this.drawerOpenSide = undefined;

    this.pageDimensions = pageDimensions || {
      width: 0,
      height: 0,
    };
    this.amountOfVisiblePages = amountOfVisiblePages;
    this.isZoomedToFit = true;
    this.zoomFactor = 1;
    this.answerStepperIcons = {
      middle: FabricService.cacheAndGroupSVG(answerSetIcon),
      next: FabricService.cacheAndGroupSVG(answerSetNext),
      prev: FabricService.cacheAndGroupSVG(answerSetPrev),
    };
    this.playerMode = mode;
    /**
     * This is set to enlarge the scale of the fabric cache.
     * This helps combat blurriness on cached groups (answer layer f.e.).
     */
    fabric.perfLimitSizeTotal = 33554432;
    fabric.maxCacheSideLimit = 8192;
  }

  initialize(height, width, disableAnswerLayerBlend, sidebarAnchor) {
    this.fabricCanvas.set({
      selectionColor: 'rgba(204,231,239,0.4)',
      renderOnAddRemove: false,
      defaultCursor: 'default',
      hoverCursor: 'default',
      moveCursor: 'default',
      freeDrawingCursor: 'crosshair',
      preserveObjectStacking: true,
    });

    this.answerCompositeOperation = disableAnswerLayerBlend ? 'source-over' : 'multiply';

    this.setCanvasDimensions(height, width);

    this.setSidebarAnchor(sidebarAnchor);
  }

  setSidebarAnchor(sidebarAnchor) {
    this.sidebarAnchor = sidebarAnchor;
  }

  setCurrentTool(tool, opts) {
    this.currentTool = tool;
    this.setCanvasDefaults();
    this.fabricCanvas.set({ selectionColor: 'rgba(204,231,239,0.4)' });

    if (tool === Tools.PENCIL || tool === Tools.MARKER) {
      this.configureDrawing(tool, opts);
    } else if (tool === Tools.CLASSIC_ERASER) {
      this.configureErasing(opts);
    } else if (tool === Tools.ANNOTATION) {
      this.configureAnnotation();
    } else if (DRAG_TOOLS.includes(tool)) {
      this.fabricCanvas.isDrawingMode = false;
      this.fabricCanvas.set({ defaultCursor: 'crosshair', moveCursor: 'crosshair', hoverCursor: 'crosshair' });
    } else if (tool === Tools.TEXT_MARKER) {
      this.fabricCanvas.isDrawingMode = false;
      this.fabricCanvas.set({ defaultCursor: 'text', moveCursor: 'text', hoverCursor: 'text' });
      this.fabricCanvas.set({ selectionColor: 'rgba(204,231,239,0.0)' });
    } else {
      this.fabricCanvas.isDrawingMode = false;
      this.fabricCanvas.set({ defaultCursor: 'default', moveCursor: 'default', hoverCursor: 'default' });
    }
  }

  setCanvasDefaults() {
    if (this.annotationCreatedHandler) this.fabricCanvas.off('mouse:up', this.annotationCreatedHandler);
    this.fabricCanvas.set({
      selectionColor: 'rgba(204,231,239,0.4)',
      selectionBorderColor: undefined,
    });
  }

  configureAnnotation() {
    this.fabricCanvas.isDrawingMode = false;
    this.fabricCanvas.set({ defaultCursor: 'crosshair', moveCursor: 'crosshair', hoverCursor: 'pointer' });

    this.fabricCanvas.on('mouse:up', this.annotationCreatedHandler);
    // hide fabric selection
    this.fabricCanvas.set({
      selectionColor: TRANSPARENT,
      selectionBorderColor: TRANSPARENT,
    });
  }

  configureDrawing(tool, opts) {
    const getColorsForTool = () => {
      switch (tool) {
        case Tools.PENCIL:
          return pencilColors;
        case Tools.MARKER:
          return markerColors;
        default:
          return pencilColors;
      }
    };

    this.fabricCanvas.freeDrawingBrush = new DrawingBrush(this.fabricCanvas, this.getVisibleBookPagesDimensions(), this.fabricCanvas.freeDrawingBrush?.freeDrawingStrategy);
    const colors = getColorsForTool();
    this.fabricCanvas.freeDrawingBrush.color = colors[opts.color];
    this.fabricCanvas.freeDrawingBrush.width = ToolSizes[tool][opts.size];
    this.fabricCanvas.freeDrawingBrush.strokeLineCap = tool === Tools.PENCIL ? 'round' : 'square';
    this.fabricCanvas.freeDrawingBrush.strokeLineJoin = tool === Tools.PENCIL ? 'round' : 'miter';

    this.fabricCanvas.isDrawingMode = !!tool;
  }

  configureErasing(opts) {
    this.fabricCanvas.isDrawingMode = true;

    this.fabricCanvas.freeDrawingBrush = new EraserBrush(this.fabricCanvas, this.getVisibleBookPagesDimensions(), this.fabricCanvas.freeDrawingBrush?.freeDrawingStrategy);
    this.fabricCanvas.freeDrawingBrush.width = ToolSizes[Tools.CLASSIC_ERASER][opts.size];
  }

  dispose() {
    this.fabricCanvas.dispose();
    if (this.animationTimeout) clearTimeout(this.animationTimeout);
    this.removePinchListeners();
  }

  handleCanvasResize(dimensions) {
    const prevWidth = this.fabricCanvas.getWidth();
    const prevHeight = this.fabricCanvas.getHeight();
    this.setCanvasDimensions(dimensions.height, dimensions.width);
    if (this.isZoomedToFit) {
      this.scaleCanvasToFit(this.zoomFactor);
    } else {
      const vp = this.fabricCanvas.viewportTransform;
      const currentLeftOffset = vp[4];
      const currentTopOffset = vp[5];

      this.setViewportLeftOffset((currentLeftOffset / prevWidth) * dimensions.width);
      this.setViewportTopOffset((currentTopOffset / prevHeight) * dimensions.height);
    }
  }

  scaleCanvasToFit(zoomLevel = 1) {
    if (this.pageDimensions.width === 0 && this.pageDimensions.height === 0) return;
    const zoomFactor = this.getBaseZoom();

    this.fabricCanvas.setZoom(zoomFactor * zoomLevel);
    this.resetCanvasViewPort();
  }

  panCanvasTo(x, y, animate, callback) {
    const ANIMATION_DURATION = 300;

    if (animate) {
      const vp = this.fabricCanvas.viewportTransform;
      const currentLeftOffset = vp[4];
      const currentTopOffset = vp[5];

      fabric.util.animate({
        startValue: currentLeftOffset,
        endValue: x,
        duration: ANIMATION_DURATION,
        onChange: value => {
          this.setViewportLeftOffset(value);
          this.fabricCanvas.requestRenderAll();
        },
        onComplete: callback,
      });
      fabric.util.animate({
        startValue: currentTopOffset,
        endValue: y,
        duration: ANIMATION_DURATION,
        onChange: value => {
          this.setViewportTopOffset(value);
          this.fabricCanvas.requestRenderAll();
        },
        onComplete: callback,
      });
    } else {
      this.setViewportLeftOffset(x);
      this.setViewportTopOffset(y);
      if (callback) callback();
    }
  }

  resetCanvasViewPort(animate, callback) {
    if (this.pageDimensions.width === 0 && this.pageDimensions.height === 0) return;

    const bookPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.BOOKPDF);

    const manualPage =
      this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.MANUALPDF && x.position === POSITIONS.LEFT) ||
      this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.MANUALPDF);

    const solutionsPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.SOLUTIONBOOKPDF);

    const zoom = this.fabricCanvas.getZoom();

    let targetX = 0;
    let targetY = 0;

    if (manualPage) {
      /* Since the manual layer is scaled we need to get the scaled dimensions instead of the normal dimensions */
      const neededWidth = zoom * (this.amountOfVisiblePages * manualPage.getScaledWidth() + (solutionsPage?.width || 0));
      const neededHeight = manualPage.getScaledHeight() * zoom;

      targetY = this.fitToWidth ? -manualPage.top * zoom + Math.round(this.fabricCanvas.height - neededHeight) / 2 : Y_AXIS_MARGIN - DOCK_HEIGHT - manualPage.top * zoom;

      let offsetX = -manualPage.left;

      if (manualPage.position === POSITIONS.RIGHT) offsetX += manualPage.getScaledWidth();
      if (solutionsPage && bookPage.positionInBook === POSITIONS.RIGHT) offsetX += solutionsPage.width;

      targetX = zoom * offsetX + Math.round(this.fabricCanvas.width - neededWidth) / 2;

      if (this.drawerOpenSide) {
        if (manualPage.position === POSITIONS.RIGHT) offsetX = -manualPage.getScaledWidth();

        if (this.drawerOpenSide === 'left') {
          const bookLeftWhenSidebarOpenToTheLeft = DRAWER_WIDTH + X_AXIS_MARGIN / 2 - (manualPage.left + offsetX) * zoom;

          targetX = Math.max(bookLeftWhenSidebarOpenToTheLeft, targetX);
        } else if (this.drawerOpenSide === 'right') {
          const bookLeftWhenSidebarOpenToTheRight = this.fabricCanvas.width - neededWidth - X_AXIS_MARGIN / 2 - DRAWER_WIDTH - (manualPage.left + offsetX) * zoom;

          targetX = Math.min(bookLeftWhenSidebarOpenToTheRight, targetX);
        }
      }
    } else {
      const neededWidth = zoom * this.pageDimensions.width * (this.amountOfVisiblePages + (solutionsPage ? 1 : 0));
      const neededHeight = this.pageDimensions.height * zoom;

      let offsetX = 0;
      if (solutionsPage && this.sidebarAnchor === POSITIONS.LEFT) offsetX += solutionsPage.width;

      targetX = Math.round((this.fabricCanvas.width - neededWidth) / 2 + offsetX * zoom);

      targetY = this.fitToWidth ? Math.round((this.fabricCanvas.height - neededHeight) / 2) : Y_AXIS_MARGIN - DOCK_HEIGHT;

      if (this.drawerOpenSide === 'left') {
        const bookLeftWhenSidebarOpenToTheLeft = DRAWER_WIDTH + X_AXIS_MARGIN / 2;
        targetX = Math.max(bookLeftWhenSidebarOpenToTheLeft, targetX);
      } else if (this.drawerOpenSide === 'right') {
        const bookLeftWhenSidebarOpenToTheRight = this.fabricCanvas.width - neededWidth - X_AXIS_MARGIN / 2 - DRAWER_WIDTH;
        targetX = Math.min(bookLeftWhenSidebarOpenToTheRight, targetX);
      }
    }

    this.isZoomedToFit = true;

    this.panCanvasTo(targetX, targetY, animate, callback);
  }

  setViewportLeftOffset(offset) {
    this.fabricCanvas.viewportTransform[4] = Math.round(offset);
    this.fabricCanvas.setViewportTransform(this.fabricCanvas.viewportTransform);
  }

  setViewportTopOffset(offset) {
    this.fabricCanvas.viewportTransform[5] = Math.round(offset);
    this.fabricCanvas.setViewportTransform(this.fabricCanvas.viewportTransform);
  }

  onFreeDrawingPathCreated = onPathDrawn => ({ path }) => {
    this.fabricCanvas.remove(path);

    path.set({ ...disableControls });
    if (onPathDrawn) onPathDrawn(path);
  };

  onFreeDrawingPathRendered = onPathRendered => () => {
    if (onPathRendered) onPathRendered();
  };

  addFreeDrawingListeners(onPathDrawn, onPathRendered) {
    this.freeDrawingCreated = this.onFreeDrawingPathCreated(onPathDrawn);
    this.pathRendered = this.onFreeDrawingPathRendered(onPathRendered);
    this.fabricCanvas.on('path:created', this.freeDrawingCreated);
    this.fabricCanvas.on('path:rendered', this.pathRendered);
  }

  removeFreeDrawingListeners() {
    this.fabricCanvas.off('path:created', this.freeDrawingCreated);
    this.fabricCanvas.off('path:rendered', this.pathRendered);
  }

  addPinchListeners(onPinchEnd, centerOffset = { x: 0, y: 0 }) {
    let zoomFactor;
    this.eventManager = new Hammer(this.fabricCanvas.upperCanvasEl, { preventDefault: true });
    this.eventManager.get('pinch').set({ enable: true });

    this.eventManager.on('pinchstart', e => {
      zoomFactor = e.scale;
    });

    this.eventManager.on('pinchin pinchout', ev => {
      if (this.currentTool === Tools.POINTER) {
        this.fabricCanvas.set({ isClickingLink: false, isDragging: false });
        const delta = this.fabricCanvas.getZoom() * (ev.scale / zoomFactor);
        zoomFactor = ev.scale;
        const calculateZoom = () => {
          const baseZoom = this.getBaseZoom();
          let scale = delta / baseZoom;

          if (scale >= zoomLevels.MAX_ZOOM_LEVEL) scale = zoomLevels.MAX_ZOOM_LEVEL;
          else if (scale <= zoomLevels.MIN_ZOOM_LEVEL) scale = zoomLevels.MIN_ZOOM_LEVEL;

          return baseZoom * scale;
        };

        const point = new fabric.Point(ev.center.x - centerOffset.x, ev.center.y - centerOffset.y);
        this.fabricCanvas.zoomToPoint(point, calculateZoom());

        this.fabricCanvas.requestRenderAll();
      }
    });

    this.eventManager.on('pinchend', () => {
      const newZoom = Number((this.fabricCanvas.getZoom() / this.getBaseZoom()).toFixed(1));
      onPinchEnd(newZoom); // Send callback with adapted zoom factor
    });
  }

  removePinchListeners() {
    if (this.eventManager) this.eventManager.destroy();
  }

  onDragMouseDown = ({ e }) => {
    const position = FabricService.getEventCoordinates(e);
    if (DRAG_TOOLS.includes(this.currentTool)) {
      this.setStartDraggingCoordinates(e);
    } else if (!this.fabricCanvas.isDrawingMode && ![Tools.ANNOTATION, Tools.TEXT_MARKER].includes(this.currentTool)) {
      // prevent panning for annotation
      this.fabricCanvas.set({
        selection: false,
        isDragging: true,
        lastPosX: position.clientX,
        lastPosY: position.clientY,
      });
    }

    this.fabricCanvas.set({
      lastPosXOnMouseDown: position.clientX,
      lastPosYOnMouseDown: position.clientY,
    });

    this.eventCountsAsClick = true; // Once we move enough this will no longer be true
  };

  onDragMouseMove = panHandler => ({ e }) => {
    const position = FabricService.getEventCoordinates(e);
    // Prevent chrome bug in which movemove event is fired on mouse down... This caused link areas to be unclickable.
    const hasMoved = this.fabricCanvas.lastPosX !== position.clientX || this.fabricCanvas.lastPosY !== position.clientY;

    // Compare with last DOWN position and skip pan when needed
    this.eventCountsAsClick =
      Boolean(this.fabricCanvas.lastPosXOnMouseDown && this.fabricCanvas.lastPosYOnMouseDown) &&
      Math.abs(this.fabricCanvas.lastPosXOnMouseDown - position.clientX) < 10 &&
      Math.abs(this.fabricCanvas.lastPosYOnMouseDown - position.clientY) < 10;

    if (this.eventCountsAsClick) return;

    if (this.fabricCanvas.isDragging && hasMoved) {
      this.fabricCanvas.relativePan({
        x: position.clientX - this.fabricCanvas.lastPosX,
        y: position.clientY - this.fabricCanvas.lastPosY,
      });

      panHandler();

      this.fabricCanvas.set({
        lastPosX: position.clientX,
        lastPosY: position.clientY,
        isClickingLink: false,
        lastPosXOnMouseDown: undefined, // Reset down position
        lastPosYOnMouseDown: undefined, // while we are still moving
      });
      this.fabricCanvas.setCursor('move');

      this.fabricCanvas.requestRenderAll();
    }
    if (DRAG_TOOLS.includes(this.currentTool)) {
      this.fabricCanvas.set({ isClickingLink: false });
    }
  };

  onDragMouseUp = (onObjectCreatedHandlers, disableZoomToFitMode) => ({ e }) => {
    if (this.fabricCanvas.isDrawingMode) return;
    if (DRAG_TOOLS.includes(this.currentTool)) {
      this.createDraggingShape(e, onObjectCreatedHandlers[this.currentTool]);
    } else {
      this.fabricCanvas.set({
        selection: true,
        isDragging: false,
        isClickingLink: this.eventCountsAsClick,
      });

      disableZoomToFitMode();
    }
  };

  addClickListener(addAnnotationCallback, setSelectedAnnotationIdCallback) {
    this.annotationCreatedHandler = this.onAnnotationCreatedHandler(addAnnotationCallback, setSelectedAnnotationIdCallback);
  }

  removeClickListener() {
    this.fabricCanvas.off('mouse:up', this.annotationCreatedHandler);
  }

  isAnnotationBelowPointer(pointerPosition) {
    const annotations = this.fabricCanvas.getObjects().filter(x => x.meta === FabricTypes.RECTWITHTEXT && x.id);

    const foundAnnotations = annotations.filter(annotation => {
      const withinX = pointerPosition.x >= annotation.left - ANNOTATION_PADDING && pointerPosition.x <= annotation.left + annotation.width + ANNOTATION_PADDING;
      const withinY = pointerPosition.y >= annotation.top - ANNOTATION_PADDING && pointerPosition.y <= annotation.top + annotation.height + ANNOTATION_PADDING;
      return withinX && withinY;
    });

    if (foundAnnotations.length === 0) return undefined;

    /**
     * The last annotation rendered is the upper one on the screen
     */
    return foundAnnotations[foundAnnotations.length - 1];
  }

  onAnnotationCreatedHandler = (addAnnotationCallback, setSelectedAnnotationIdCallback) => opt => {
    // cancel if user is selecting text
    const activeObject = this.fabricCanvas.getActiveObject();

    if (activeObject && activeObject.meta === FabricTypes.RECTWITHTEXT && activeObject.getSelectedText()) return;
    if (opt.target?.meta === FabricTypes.SOLUTIONANSWERPDF) {
      // deselect any active annotations
      setSelectedAnnotationIdCallback(undefined);

      return;
    }

    const pointerPosition = this.fabricCanvas.getPointer(opt.e);

    const annotation = this.isAnnotationBelowPointer(pointerPosition);

    if (annotation) {
      // in case user drags onto the currently selected annotation
      this.fabricCanvas.setActiveObject(annotation);
      annotation.enterEditing();

      setSelectedAnnotationIdCallback(annotation.id);
      return;
    }

    if (this.eventCountsAsClick) {
      const renderDimensions = this.playerMode === playerMode.BOOK ? this.getOriginalBookDimensions() : this.getSinglePageDimensions();

      const scaledShape = {
        id: shortid.generate(),
        top: pointerPosition.y / renderDimensions.height,
        left: pointerPosition.x / renderDimensions.width,
        width: 0,
        height: 0,
        fontId: AnnotationFonts.MYRIAD,
      };

      addAnnotationCallback(scaledShape);
    } else {
      setSelectedAnnotationIdCallback(undefined);
    }
  };

  renderAnnotations(annotations, selectedAnnotationId, placeHolderText, isSinglePage, isRightPageOnSpread, onSave, selectedAnnotationRef, isSolutionsPageVisible) {
    const renderDimensions = this.playerMode === playerMode.BOOK ? this.getOriginalBookDimensions() : this.getSinglePageDimensions();

    if (renderDimensions.width < 0 || renderDimensions.height < 0) return;

    const solutionsPageWithSidebarLeft = isSolutionsPageVisible && this.sidebarAnchor === POSITIONS.LEFT;
    const solutionsPageWithSidebarRight = isSolutionsPageVisible && this.sidebarAnchor === POSITIONS.RIGHT;

    const isOnSolutionsPageWithSidebarLeft = ({ top, left }) => top >= 0 && top <= 1 && solutionsPageWithSidebarLeft && left >= -0.5 && left <= 0;
    const isOnSolutionsPageWithSidebarRight = ({ top, left }) => top >= 0 && top <= 1 && solutionsPageWithSidebarRight && left >= 0.5 && left <= 1;

    // annotations are always saved on spread mode, so we need to calculate the left position for other modes
    const calculateLeftPosition = annotation => {
      const { top, left } = annotation;

      // move annotations on or besides the book page to the left
      if (solutionsPageWithSidebarLeft && left <= 0 && top >= 0 && top <= 1) return left - 0.5;

      // move annotations on the solutions page to the book page
      if (isOnSolutionsPageWithSidebarRight(annotation)) return left - 0.5;

      // annotations besides solutions page keep same position
      if (solutionsPageWithSidebarRight && left >= 0.5 && top >= 0 && top <= 1) return left;

      // move annotations to the left because right page is start page
      if (isSinglePage && isRightPageOnSpread && left > 0) return left - 0.5;

      // move annotations to the left because there is only one page (no spread)
      if (isSinglePage && left >= 1) return left - 0.5;

      return left;
    };

    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === FabricTypes.RECTWITHTEXT));

    annotations.forEach(annotation => {
      const textOptions = {
        id: annotation.id,
        top: annotation.top * renderDimensions.height,
        left: calculateLeftPosition(annotation) * renderDimensions.width,
        width: annotation.width * renderDimensions.width,
        height: annotation.height * renderDimensions.height,
        fill: AnnotationTextColors[annotation.color],
        fontSize: ToolSizes[Tools.ANNOTATION][annotation.fontSize],
        fontFamily: FontFamilies[annotation.fontId],
        backgroundColor: annotationBackgroundColors[annotation.backgroundColor],
        padding: ANNOTATION_PADDING,
        resized: annotation.resized,
        resizeHeight: annotation.resizeHeight,
      };

      const rectWithText = createRectWithText(annotation, textOptions, placeHolderText);

      rectWithText.on('changed', () => {
        selectedAnnotationRef.current.width = rectWithText.width / renderDimensions.width;
        selectedAnnotationRef.current.height = rectWithText.height / renderDimensions.height;
        selectedAnnotationRef.current.text = rectWithText.text;

        onSave();
      });

      rectWithText.on('resized', ({ width, height, resizeHeight }) => {
        selectedAnnotationRef.current.width = width / renderDimensions.width;
        selectedAnnotationRef.current.height = height / renderDimensions.height;
        selectedAnnotationRef.current.resized = true;
        selectedAnnotationRef.current.resizeHeight = resizeHeight;
        selectedAnnotationRef.current.text = rectWithText.text;

        onSave();
      });

      rectWithText.on('moved', () => {
        let left = rectWithText.left / renderDimensions.width;
        const top = rectWithText.top / renderDimensions.height;

        if (isOnSolutionsPageWithSidebarLeft({ top, left }) || isOnSolutionsPageWithSidebarRight({ top, left })) {
          rectWithText.set({
            top: selectedAnnotationRef.current.top * renderDimensions.height,
            left: calculateLeftPosition(selectedAnnotationRef.current) * renderDimensions.width,
          });

          rectWithText.fire('changed');

          return onSave();
        }

        /**
         * Annotations on the sides of the book always have to be stored correctly.
         * They are indicated trough their left property being:
         * left side: left < 0
         * right side: left > 1
         */
        if (isSinglePage && isRightPageOnSpread && left > 0) left += 0.5;

        if (isSinglePage && !isRightPageOnSpread && left > 0.5) left += 0.5;

        selectedAnnotationRef.current.left = left;
        selectedAnnotationRef.current.top = top;

        if (solutionsPageWithSidebarLeft && left <= -0.5 && top > 0 && top < 1) {
          selectedAnnotationRef.current.left = left + 0.5;
        }

        if (solutionsPageWithSidebarRight && left >= 1 && top > 0 && top < 1) {
          selectedAnnotationRef.current.left = left - 0.5;
        }

        rectWithText.set({
          top: selectedAnnotationRef.current.top * renderDimensions.height,
          left: calculateLeftPosition(selectedAnnotationRef.current) * renderDimensions.width,
        });

        rectWithText.fire('changed');

        return onSave();
      });

      this.fabricCanvas.add(rectWithText);
    });

    if (selectedAnnotationId) {
      const selected = this.fabricCanvas.getObjects().find(x => x.id === selectedAnnotationId);
      if (selected) selected.enterEditing();
    } else {
      this.fabricCanvas.discardActiveObject();
    }
  }

  addDragListeners(onObjectCreatedHandlers, syncViewportTransform, disableZoomToFit) {
    const panHandler = () => {
      disableZoomToFit();
      syncViewportTransform();
      document.dispatchEvent(
        new CustomEvent('canvas-panned', {
          detail: this.fabricCanvas.viewportTransform,
        }),
      );
    };

    this.dragMoveHandler = this.onDragMouseMove(panHandler);
    this.dragUpHandler = this.onDragMouseUp(onObjectCreatedHandlers, disableZoomToFit);

    this.fabricCanvas.on('mouse:down', this.onDragMouseDown);
    this.fabricCanvas.on('mouse:move', this.dragMoveHandler);
    this.fabricCanvas.on('mouse:up', this.dragUpHandler);
  }

  removeDragListeners() {
    this.fabricCanvas.off('mouse:down', this.onDragMouseDown);
    this.fabricCanvas.off('mouse:move', this.dragMoveHandler);
    this.fabricCanvas.off('mouse:up', this.dragUpHandler);
  }

  getBaseZoom() {
    if (this.pageDimensions.width === 0 && this.pageDimensions.height === 0) return 1;

    const bookPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.BOOKPDF);
    const manualPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.MANUALPDF);
    const solutionsPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.SOLUTIONBOOKPDF);

    if (manualPage) {
      const scaleHeightToFit = (this.fabricCanvas.height - Y_AXIS_MARGIN) / manualPage.getScaledHeight();
      const scaleWidthToFit = (this.fabricCanvas.width - X_AXIS_MARGIN) / (manualPage.getScaledWidth() * this.amountOfVisiblePages + (solutionsPage?.width || 0));

      if (scaleWidthToFit < scaleHeightToFit) this.fitToWidth = true;
      else this.fitToWidth = false;

      return Math.min(scaleHeightToFit, scaleWidthToFit);
    }

    if (bookPage) {
      const scaleWidthToFit = (this.fabricCanvas.width - X_AXIS_MARGIN) / (bookPage.getScaledWidth() * (this.amountOfVisiblePages + (solutionsPage ? 1 : 0)));

      const scaleHeightToFit = (this.fabricCanvas.height - Y_AXIS_MARGIN) / bookPage.getScaledHeight();

      if (scaleWidthToFit < scaleHeightToFit) this.fitToWidth = true;
      else this.fitToWidth = false;

      return Math.min(scaleHeightToFit, scaleWidthToFit);
    }

    const scaleHeightToFit = (this.fabricCanvas.height - Y_AXIS_MARGIN) / this.pageDimensions.height;
    const scaleWidthToFit = (this.fabricCanvas.width - X_AXIS_MARGIN) / (this.pageDimensions.width * this.amountOfVisiblePages);

    if (scaleWidthToFit < scaleHeightToFit) this.fitToWidth = true;
    else this.fitToWidth = false;

    return Math.min(scaleHeightToFit, scaleWidthToFit);
  }

  createDraggingShape(e, objectCreatedCallback) {
    const end = this.fabricCanvas.getPointer(e);
    const start = this.dragStartPoint;
    const top = Math.min(end.y, start.y);
    const left = Math.min(end.x, start.x);
    const offsetTop = Math.max(end.y, start.y);
    const offsetLeft = Math.max(end.x, start.x);

    const height = offsetTop - top;
    const width = offsetLeft - left;

    if (!width || !height) return false;

    const newRect = {
      top: top / this.pageDimensions.height,
      left: left / (this.pageDimensions.width * 2),
      width: width / (this.pageDimensions.width * 2),
      height: height / this.pageDimensions.height,
    };

    if (this.currentTool === Tools.SELECTION_ERASER) {
      // if we are select-erasing in singlepage mode, make sure we can not erase outside the visible page
      const visibleWidth = this.pageDimensions.width * this.amountOfVisiblePages;
      const overflowLeft = left < 0 ? -left : 0; // the fraction that is painted left of the bookpage
      const overflowRight = left + width > visibleWidth ? left + width - visibleWidth : 0; // the fraction that is painted right of the bookpage

      const correctedWidth = width - overflowLeft - overflowRight;
      const correctedLeft = overflowLeft !== 0 ? 0 : left;

      const rect = new fabric.Rect({
        top,
        left: correctedLeft,
        width: correctedWidth,
        height,
        globalCompositeOperation: 'destination-out',
      });

      return objectCreatedCallback(rect);
    }

    if (this.currentTool === Tools.ZOOM_SELECT) {
      const maxZoomLevel = this.getBaseZoom() * zoomLevels.MAX_ZOOM_LEVEL;

      const zoomX = this.fabricCanvas.height / height;
      const zoomY = this.fabricCanvas.width / width;

      const nextZoom = Math.min(zoomX, zoomY, maxZoomLevel);

      const reduxZoomLevel = parseFloat((nextZoom / this.getBaseZoom()).toFixed(1));

      const point = new fabric.Rect({
        width,
        height,
        left,
        top,
      }).getCenterPoint();

      this.fabricCanvas.setZoom(1);
      this.fabricCanvas.absolutePan(point);
      this.fabricCanvas.setZoom(nextZoom);

      this.setViewportLeftOffset(this.fabricCanvas.viewportTransform[4] + this.fabricCanvas.width / 2);
      this.setViewportTopOffset(this.fabricCanvas.viewportTransform[5] + this.fabricCanvas.height / 2);

      return objectCreatedCallback(reduxZoomLevel);
    }

    return objectCreatedCallback(new fabric.Rect(newRect));
  }

  createClippingShapeForSinglePage() {
    const dimensions = this.getSinglePageDimensions();
    return new fabric.Rect({
      top: 0,
      left: 0,
      ...dimensions,
      absolutePositioned: true,
    });
  }

  createClippingShapeForSpread() {
    const dimensions = this.getOriginalBookDimensions();
    return new fabric.Rect({
      top: 0,
      left: 0,
      ...dimensions,
      absolutePositioned: true,
    });
  }

  applyShapesToAnswerPages(shapes, isSinglePage, isRightPage) {
    if (!this.renderedAnswerPageLeft) return [];
    const existingAnswers = this.fabricCanvas.getObjects().filter(object => object.meta === FabricTypes.ANSWERPDF);

    this.fabricCanvas.remove(...existingAnswers);
    const bookDimensions = this.getOriginalBookDimensions();

    const shapeCollections = Object.values(shapes);

    const leftPage = this.renderedAnswerPageLeft && this.renderedAnswerPageLeft();
    const rightPage = this.renderedAnswerPageRight && this.renderedAnswerPageRight();

    const clipPaths = [];

    if (leftPage) {
      const clipShapes = shapeCollections[0].map(
        shape =>
          new fabric.Rect({
            ...shape,
            top: shape.top * bookDimensions.height,
            left: (isSinglePage && isRightPage ? shape.left - this.RIGHT_PAGE_OFFSET : shape.left) * bookDimensions.width,
            height: shape.height * bookDimensions.height,
            width: shape.width * bookDimensions.width,
            globalCompositeOperation: shape.action === 'hide' ? 'destination-out' : 'source-over',
          }),
      );

      const leftClipPath = new fabric.Group(clipShapes, { absolutePositioned: true });

      clipPaths.push(leftClipPath);

      leftPage.set({
        clipPath: leftClipPath,
      });

      this.fabricCanvas.add(leftPage);
    }

    if (rightPage) {
      const clipShapes = shapeCollections[1].map(
        shape =>
          new fabric.Rect({
            ...shape,
            top: shape.top * bookDimensions.height,
            left: shape.left * bookDimensions.width,
            height: shape.height * bookDimensions.height,
            width: shape.width * bookDimensions.width,
            globalCompositeOperation: shape.action === 'hide' ? 'destination-out' : 'source-over',
          }),
      );

      const rightClipPath = new fabric.Group(clipShapes, { absolutePositioned: true });

      clipPaths.push(rightClipPath);

      rightPage.set({
        clipPath: rightClipPath,
      });

      this.fabricCanvas.add(rightPage);
    }

    return clipPaths;
  }

  clipAnswers(shapes, isSinglePage, isRightPage, animatedId) {
    if (!shapes || shapes.length === 0) return;
    const clipPaths = this.applyShapesToAnswerPages(shapes, isSinglePage, isRightPage);

    if (animatedId) {
      const clipObjects = clipPaths.map(x => x.getObjects()).flat();

      const objectsToAnimate = clipObjects.filter(x => RegExp(`${animatedId}$`).test(x.id));
      if (objectsToAnimate.length > 0) {
        objectsToAnimate.forEach(x => x.set({ globalCompositeOperation: 'destination-out' }));
        this.renderAll();
        this.flickerAnimateObjects(objectsToAnimate, 'source-over');
      }
    }
  }

  renderPDFPage(dataUrl, position, isAnswer) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        dataUrl,
        oImg => {
          if (oImg === null) reject(new Error());

          const renderedPage = oImg;

          this.setPageDimensions(renderedPage.height, renderedPage.width);

          renderedPage.set({
            position,
            meta: isAnswer ? FabricTypes.ANSWERPDF : FabricTypes.BOOKPDF,
            left: position === POSITIONS.LEFT ? 0 : renderedPage.width,
          });

          if (isAnswer) {
            if (position === POSITIONS.LEFT) {
              this.renderedAnswerPageLeft = renderedPage;
            } else {
              this.renderedAnswerPageRight = renderedPage;
            }
            resolve();
          } else {
            this.fabricCanvas.add(renderedPage);
            resolve();
          }
        },
        {
          evented: false,
          ...disableControls,
        },
      );
    });
  }

  cacheAnswerPage(image, position, isSolutionsPageVisible) {
    if (position === POSITIONS.LEFT) {
      this.renderedAnswerPageLeft = () => {
        const leftImage = new fabric.Image(image);

        leftImage.set({
          meta: FabricTypes.ANSWERPDF,
          position,
          left: 0,
          evented: false,
          globalCompositeOperation: this.answerCompositeOperation,
          ...disableControls,
        });

        return leftImage;
      };

      // only left page because spread is not supported for solutions page
      if (isSolutionsPageVisible) {
        const answerPage = this.renderedAnswerPageLeft();

        const left = this.sidebarAnchor === POSITIONS.LEFT ? -answerPage.width : answerPage.width;

        answerPage.set({
          left,
          meta: FabricTypes.SOLUTIONANSWERPDF,
          evented: true,
          hoverCursor: 'default',
        });

        this.fabricCanvas.add(answerPage);
      }
    } else {
      this.renderedAnswerPageRight = () => {
        const rightImage = new fabric.Image(image);

        rightImage.set({
          meta: FabricTypes.ANSWERPDF,
          position,
          left: rightImage.width,
          evented: false,
          globalCompositeOperation: this.answerCompositeOperation,
          ...disableControls,
        });

        return rightImage;
      };
    }
  }

  renderBookPage(image, position, positionInBook, isSolutionsPageVisible = false) {
    const renderedPage = new fabric.Image(image);
    this.setPageDimensions(renderedPage.height, renderedPage.width);

    if (this.fabricCanvas.upperCanvasEl) {
      this.fabricCanvas.upperCanvasEl.removeAttribute('title');
    }

    renderedPage.set({
      position,
      positionInBook,
      meta: FabricTypes.BOOKPDF,
      left: position === POSITIONS.LEFT ? 0 : renderedPage.width,
      evented: false,
      backgroundColor: 'white',
      ...disableControls,
    });

    this.fabricCanvas.add(renderedPage);

    let shadowBox = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.BOOKSHADOW);

    if (!shadowBox) {
      shadowBox = createDropShadow(FabricTypes.BOOKSHADOW, 0, 0);

      this.fabricCanvas.sendToBack(shadowBox);
    }

    // -2 because when set to the same size as the total book, the box overlaps the book
    shadowBox.set({
      width: renderedPage.width * this.amountOfVisiblePages - 2,
      height: renderedPage.height - 2,
    });

    if (isSolutionsPageVisible) {
      const solutionsPage = new fabric.Image(image);

      const left = this.sidebarAnchor === POSITIONS.LEFT ? -renderedPage.width : renderedPage.width;

      solutionsPage.set({
        left,
        meta: FabricTypes.SOLUTIONBOOKPDF,
        evented: false,
        backgroundColor: 'white',
        ...disableControls,
      });

      const solutionsPageBorder = new fabric.Rect({
        width: solutionsPage.width - 2,
        height: solutionsPage.height - 2,
        left,
        stroke: 'rgb(103, 194, 55)',
        strokeWidth: 4,
        evented: false,
        fill: 'transparent',
        ...disableControls,
      });

      this.fabricCanvas.add(solutionsPage);
      this.fabricCanvas.add(solutionsPageBorder);
      solutionsPageBorder.bringToFront();

      shadowBox.set({ width: solutionsPage.width + shadowBox.width });

      if (this.sidebarAnchor === POSITIONS.LEFT) shadowBox.set({ left: -solutionsPage.width });
    }
  }

  clearBookPages() {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => [FabricTypes.BOOKPDF, FabricTypes.BOOKSHADOW, FabricTypes.SOLUTIONBOOKPDF].includes(x.meta)));
  }

  setBookPagesInvisible() {
    this.fabricCanvas
      .getObjects()
      .filter(x => [FabricTypes.BOOKPDF, FabricTypes.BOOKSHADOW, FabricTypes.SOLUTIONBOOKPDF].includes(x.meta))
      .forEach(x => x.set({ visible: false }));
  }

  renderManualPage(image, position, isRightPage, amountOfPages, isSolutionsPageVisible) {
    const bookPage = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.BOOKPDF);

    if (!bookPage) return;

    const manualPageWidth = bookPage.width / this.manualMargins.width;

    const manualPageHeight = bookPage.height / this.manualMargins.height;

    const offset = position === POSITIONS.LEFT ? 0 : manualPageWidth;

    const renderedPage = new fabric.Image(image);

    // isRightPage is only true when it is a single right page
    // then the manual should be positioned on the right side of the book page
    let left = isRightPage ? -this.manualMargins.left : -this.manualMargins.left * manualPageWidth * 2 + offset;

    if (!isRightPage && isSolutionsPageVisible && this.sidebarAnchor === POSITIONS.LEFT) left -= bookPage.width;
    if (isRightPage && isSolutionsPageVisible && this.sidebarAnchor === POSITIONS.RIGHT) left += bookPage.width;

    renderedPage.set({
      position,
      meta: FabricTypes.MANUALPDF,
      evented: false,
      backgroundColor: 'white',
      top: -this.manualMargins.top * manualPageHeight,
      left,
      scaleX: manualPageWidth / renderedPage.width,
      scaleY: manualPageHeight / renderedPage.height,
      ...disableControls,
    });

    this.fabricCanvas.sendToBack(renderedPage);

    let shadowBox = this.fabricCanvas.getObjects().find(x => x.meta === FabricTypes.MANUALSHADOW);

    if (!shadowBox) shadowBox = createDropShadow(FabricTypes.MANUALSHADOW, renderedPage.top, renderedPage.left);

    shadowBox.set({
      width: renderedPage.getScaledWidth() * amountOfPages - 2, // -2 because when set to the same size as the total manual, the box overlaps the manual
      height: renderedPage.getScaledHeight() - 2,
    });

    this.fabricCanvas.sendToBack(shadowBox);
  }

  clearPageCache() {
    this.renderedAnswerPageLeft = undefined;
    this.renderedAnswerPageRight = undefined;
  }

  setDrawerOpenSide(side) {
    this.drawerOpenSide = side;
  }

  shiftViewportForDrawer(side, callback) {
    const animate = this.drawerOpenSide !== side;

    this.setDrawerOpenSide(side);

    this.resetCanvasViewPort(animate, callback);
  }

  renderAreasAndAddListeners(linkAreas, isStandalonePage, isSinglePage, isRightPage, areaClickHandler, evented) {
    const bookDimensions = this.getOriginalBookDimensions();
    if (bookDimensions.width <= 0 || bookDimensions.height <= 0) return;

    this.removeObjectsOfType(FabricTypes.LINKAREA);

    const clipPath = this.createClippingShapeForSinglePage();
    const areas = linkAreas
      .map(linkArea => {
        const { shape, displayOptions } = linkArea;

        const scaledShape = {
          top: shape.top * bookDimensions.height,
          left: shape.left * bookDimensions.width,
          width: shape.width * bookDimensions.width,
          height: shape.height * bookDimensions.height,
        };

        const clippedObject = FabricService.getClippedLinkArea(clipPath, scaledShape, isStandalonePage, isSinglePage, isRightPage);

        if (clippedObject.width <= 0) return null;

        const fill = displayOptions && displayOptions.highlighted ? 'rgba(204, 231, 239, 1)' : TRANSPARENT;

        const rect = new fabric.Rect({
          ...clippedObject,
          angle: shape.angle,
          fill,
          rx: 12,
          ry: 12,
          globalCompositeOperation: 'multiply',
          stroke: 'rgba(0,0,0, 0.3)',
          strokeWidth: 0,
          evented,
        });

        rect.set({
          linkAreaId: linkArea.id,
          type: FabricTypes.LINKAREA,
          selectable: false,
        });

        if (!isStandalonePage && isSinglePage && isRightPage) {
          rect.set({ left: (clippedObject.left / bookDimensions.width - this.RIGHT_PAGE_OFFSET) * bookDimensions.width });
        }

        let appendZeroWidthSpace = false;

        rect.on('mouseover', () => {
          if (this.currentTool === Tools.POINTER) {
            // Firefox bug https://pelckmans.atlassian.net/browse/PAKP-5409
            // Fix by appending a zero width (invisible) character to the name so the value changes and FF shows the title on second hover
            // https://unicode-table.com/en/200B/
            this.fabricCanvas.upperCanvasEl.title = linkArea.name + (appendZeroWidthSpace ? '\u200B' : '');
            rect.hoverCursor = 'pointer';
            rect.set({
              fill: 'rgba(204, 231, 239, 1)',
              strokeWidth: 2,
            });
          } else if (this.currentTool !== Tools.TEXT_MARKER) {
            this.fabricCanvas.setCursor('crosshair');
          }

          this.fabricCanvas.requestRenderAll();
        });

        rect.on('mouseout', () => {
          appendZeroWidthSpace = !appendZeroWidthSpace;
          this.fabricCanvas.upperCanvasEl.removeAttribute('title');
          rect.set({
            fill,
            strokeWidth: 0,
            hoverCursor: this.fabricCanvas.get('hoverCursor'),
          });
          this.fabricCanvas.requestRenderAll();
        });

        rect.on('mousedown', () => {
          this.fabricCanvas.set({ isClickingLink: true });
        });

        rect.on('mouseup', options => {
          if (this.fabricCanvas.isClickingLink) {
            this.fabricCanvas.set({ isClickingLink: false });
            areaClickHandler(options);
          }
        });
        return rect;
      })
      .filter(area => area !== null);

    this.fabricCanvas.add(...areas);

    areas.forEach(area => area.bringToFront());
  }

  removeAllObjects() {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects());
  }

  removeObjectsOfType(type) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects(type));
  }

  renderAll() {
    this.fabricCanvas.requestRenderAll();
  }

  getOriginalBookDimensions() {
    return {
      width: this.pageDimensions.width * 2,
      height: this.pageDimensions.height,
    };
  }

  getSinglePageDimensions() {
    return this.pageDimensions;
  }

  getVisibleBookPagesDimensions() {
    return {
      width: this.pageDimensions.width * this.amountOfVisiblePages,
      height: this.pageDimensions.height,
    };
  }

  setZoom(zoomLevel, point) {
    this.zoomFactor = zoomLevel;
    const baseZoom = this.getBaseZoom();
    const normalizedZoomLevel = zoomLevel * baseZoom;
    const canvasCenter = this.fabricCanvas.getCenter();
    const standardPoint = new fabric.Point(canvasCenter.left, canvasCenter.top);
    this.fabricCanvas.zoomToPoint(point || standardPoint, normalizedZoomLevel);
  }

  setStartDraggingCoordinates(e) {
    this.dragStartPoint = this.fabricCanvas.getPointer(e);
  }

  clearMarkings() {
    if (this.markingsGroup) this.fabricCanvas.remove(this.markingsGroup);
  }

  clearWhitepage() {
    this.markingsGroup.remove(...this.markingsGroup.getObjects());
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === FabricTypes.RECTWITHTEXT));
  }

  addWhitepageHeader(name = '', clickHandler, tooltipText) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === 'whitepageheader'));

    const x = new fabric.Text('f', {
      left: 75,
      top: 75,
      fontFamily: 'pelckmans-3',
      fill: '#004d62',
      fontSize: 75,
      hoverCursor: 'pointer',
      meta: 'whitepageheader',
      ...disableControls,
    });

    const title = new fabric.Text(name, {
      left: x.left + Math.round(x.width) + 40,
      top: x.top,
      fontSize: 50,
      fill: '#004d62',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      meta: 'whitepageheader',
      ...disableControls,
    });

    x.set({
      height: Math.round(x.height),
      width: Math.round(x.width),
    });

    title.set({
      height: Math.round(title.height),
      width: Math.round(title.width),
      charSpacing: 1,
    });

    x.on('mousemove', e => {
      e.target.canvas.upperCanvasEl.title = tooltipText;
    });

    x.on('mouseout', e => {
      e.target.canvas.upperCanvasEl.removeAttribute('title');
    });

    x.on('mousedown', () => {
      if (this.getCurrentTool() === Tools.POINTER) {
        this.fabricCanvas.set({ isClickingLink: true });
      }
    });

    x.on('mouseup', () => {
      if (this.fabricCanvas.isClickingLink) clickHandler();
    });

    this.fabricCanvas.add(x);
    this.fabricCanvas.add(title);
  }

  showMarkings(markings, isSinglePage, isRightPage) {
    fabric.util.enlivenObjects(markings, objs => {
      this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === 'temp_highlights'));
      this.clearMarkings();
      this.markingsGroup = new fabric.Group(objs, {
        ...disableControls,
        evented: false,
        absolutePositioned: true,
        meta: FabricTypes.MARKINGS,
      });

      this.fabricCanvas.add(this.markingsGroup);

      const hasDimensions = this.pageDimensions.width !== 0 && this.pageDimensions.height !== 0;
      if (!hasDimensions) return;

      this.markingsGroup.clipPath = isSinglePage ? this.createClippingShapeForSinglePage() : this.createClippingShapeForSpread();
      if (hasDimensions && isSinglePage && isRightPage) {
        this.markingsGroup.set({
          left: this.markingsGroup.left - this.pageDimensions.width,
        });
      }

      this.fabricCanvas.requestRenderAll();
    });
  }

  cleanUpAnswerSteppers() {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === FabricTypes.ANSWERSTEPPERICONS));
  }

  async renderAnswerSteppers(sets, isStandalonePage, isSinglePage, isRightPage, activeSet, activeIndex, clickHandler, onNext, onPrev) {
    const bookDimensions = this.getOriginalBookDimensions();

    const [middleIcon, nextIcon, prevIcon] = await Promise.all([this.answerStepperIcons.middle, this.answerStepperIcons.next, this.answerStepperIcons.prev]);

    this.cleanUpAnswerSteppers();

    return Promise.all(
      sets
        .map(async ({ answers, shape, id }) => {
          const active = id === activeSet;
          let index;
          if (!activeIndex && activeIndex !== 0) {
            index = -1;
          } else {
            index = activeIndex;
          }
          const canGoNext = active && index + 1 < (answers || []).length;
          const canGoPrev = activeIndex >= 0;
          const clipPath = this.createClippingShapeForSinglePage();
          const [middle, next, prev] = await Promise.all([FabricService.cloneObject(middleIcon), FabricService.cloneObject(nextIcon), FabricService.cloneObject(prevIcon)]);

          const icons = { middle, next, prev };
          const stepper = createAnswerStepper(id, icons, active, clickHandler, this.getCurrentTool, canGoNext, canGoPrev, onNext, onPrev, {
            size: shape.size * bookDimensions.width,
            left: shape.left * bookDimensions.width,
            top: shape.top * bookDimensions.height,
            clipPath,
            isStandalonePage,
            isSinglePage,
            isRightPage,
          });

          if (!stepper) return null;

          if (!isStandalonePage && isSinglePage && isRightPage) {
            stepper.forEach(icon => {
              icon.set({ left: icon.left - this.RIGHT_PAGE_OFFSET * bookDimensions.width });
            });
          }

          this.fabricCanvas.add(...stepper);
          return stepper;
        })
        .filter(stepper => stepper !== null),
    );
  }

  setCanvasDimensions(height, width) {
    this.fabricCanvas.setDimensions({
      height,
      width,
    });
  }

  setPageDimensions(height, width) {
    this.pageDimensions = {
      height,
      width,
    };
  }

  setAmountOfVisiblePages(amount) {
    this.amountOfVisiblePages = amount;
  }

  getCurrentTool = () => this.currentTool;

  offsetShape(shape, drawnOnSingle, isOnRightPage) {
    if (drawnOnSingle && isOnRightPage) {
      shape.set({
        left: shape.left + this.RIGHT_PAGE_OFFSET,
      });
    }

    return shape;
  }

  offsetDrawings(shape, drawnOnSingle, isOnRightPage) {
    if (drawnOnSingle && isOnRightPage) {
      shape.set({
        left: shape.left + this.pageDimensions.width,
      });
    }

    return shape;
  }

  renderTempHighlights(shapes) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.meta === 'temp_highlights'));

    this.fabricCanvas.add(
      new fabric.Group(shapes, {
        meta: 'temp_highlights',
        evented: false,
        hasBorders: false,
        hasControls: false,
      }),
    );

    this.fabricCanvas.requestRenderAll();
  }

  renderTempCircle = circleOptions => {
    const circle = FabricService.getCircle({
      ...circleOptions,
      evented: false,
      hasBorders: false,
      hasControls: false,
    });

    this.markingsGroup.remove(...this.markingsGroup.getObjects('circle').filter(c => c.meta === 'temp_drafting_compass_circle'));

    circle.meta = 'temp_drafting_compass_circle';
    circle.set({
      strokeLineCap: this.fabricCanvas.freeDrawingBrush.strokeLineCap,
      strokeLineJoin: this.fabricCanvas.freeDrawingBrush.strokeLineJoin,
    });

    this.markingsGroup.addWithUpdate(circle);

    this.fabricCanvas.requestRenderAll();
  };

  static cacheAndGroupSVG(imageUrl) {
    return new Promise((resolve, reject) => {
      fabric.loadSVGFromURL(imageUrl, (objects, options) => {
        if (!objects) reject(new Error('Invalid URL'));
        const groupedSvg = fabric.util.groupSVGElements(objects, options);

        resolve(groupedSvg);
      });
    });
  }

  static cloneObject(object) {
    return new Promise(resolve => {
      object.clone(cloned => resolve(cloned));
    });
  }

  flickerAnimateObjects(objects, target, count = 0) {
    if (this.animationTimeout) clearTimeout(this.animationTimeout);
    this.animationTimeout = setTimeout(() => {
      objects.forEach(object => {
        object.set({ globalCompositeOperation: target });
        this.fabricCanvas.requestRenderAll();
        if (count < 1) {
          const newTarget = target === 'destination-out' ? this.answerCompositeOperation : 'destination-out';
          const newCount = target !== 'destination-out' ? count : count + 1;
          this.flickerAnimateObjects(objects, newTarget, newCount);
        }
      });
    }, 125);
  }

  static getEventCoordinates(e) {
    const isTouch = ['touchmove', 'touchstart', 'touchend'].includes(e.type);
    return {
      clientX: isTouch ? e.changedTouches[0].clientX : e.clientX,
      clientY: isTouch ? e.changedTouches[0].clientY : e.clientY,
    };
  }

  static getClippedLinkArea(clipPath, scaledShape, isStandalonePage, isSinglePage, isRightPage) {
    const scaledShapeRight = scaledShape.left + scaledShape.width;
    const scaledShapeBottom = scaledShape.top + scaledShape.height;
    const clippedObject = {};

    const pageLeftCoordinate = isSinglePage && isRightPage && !isStandalonePage ? clipPath.width : 0;
    const pageRightCoordinate = isSinglePage ? pageLeftCoordinate + clipPath.width : clipPath.width * 2;

    clippedObject.left = scaledShape.left < pageLeftCoordinate ? pageLeftCoordinate : scaledShape.left;
    clippedObject.top = scaledShape.top < 0 ? 0 : scaledShape.top;

    const leftDifference = clippedObject.left - scaledShape.left;
    const topDifference = clippedObject.top - scaledShape.top;

    clippedObject.height = scaledShapeBottom > clipPath.height ? clipPath.height - clippedObject.top - 1 : scaledShape.height - topDifference;
    clippedObject.width = scaledShapeRight > pageRightCoordinate ? pageRightCoordinate - clippedObject.left - 1 : scaledShape.width - leftDifference;

    return clippedObject;
  }

  getViewportTransform() {
    return this.fabricCanvas.viewportTransform;
  }

  setManualMargins(margins) {
    this.manualMargins = margins;
  }

  getTempCircleToPersist() {
    const circleToSave = this.markingsGroup.getObjects('circle').find(circle => circle.meta === 'temp_drafting_compass_circle');
    this.markingsGroup.remove(circleToSave);

    if (circleToSave) {
      delete circleToSave.meta;
    }

    return circleToSave;
  }

  static getSelectionShapes(shapes) {
    return shapes.map(
      ({ top, left, height, width, backgroundColor }) =>
        new fabric.Rect({
          top,
          left,
          height,
          width,
          fill: backgroundColor,
          ...disableControls,
        }),
    );
  }

  static getCircle(arcOptions) {
    return new fabric.Circle(arcOptions);
  }
}
