import React from 'react';
import { flushSync, render } from 'react-dom';
import { Jodit } from 'jodit';

import TextEditorBold from '@pelckmans/business-components/icons/TextEditorBold';
import TextEditorUnderlineIcon from '@pelckmans/business-components/icons/TextEditorUnderline';
import TextEditorItalicIcon from '@pelckmans/business-components/icons/TextEditorItalic';

function renderToString(elem) {
  const div = document.createElement('div');

  flushSync(() => {
    render(elem, div);
  });

  return div.innerHTML;
}

export function updateFontSizeIcon(editor) {
  editor.toolbar.buttons
    .find(x => x.name === 'fontsize')
    .state.on('change.value', (property, prevValue, nextValue) => {
      const fontSizeIconWrapper = document.querySelector('.jodit-toolbar-button__button .jodit-icon_fontsize').parentElement;

      fontSizeIconWrapper.innerHTML = `<svg class="jodit-icon jodit-icon_fontsize" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" ><text x="50%" y="50%" lengthAdjust="spacing" font-size="1.5em" text-anchor="middle" dy=".3em" font-weight="bold">${nextValue}</text></svg>`;
    });
}

export default function initJodit() {
  Jodit.modules.Icon.set('bold', renderToString(<TextEditorBold />));
  Jodit.modules.Icon.set('underline', renderToString(<TextEditorUnderlineIcon />));
  Jodit.modules.Icon.set('italic', renderToString(<TextEditorItalicIcon />));
  Jodit.modules.Icon.set('fontsize', '<div class="jodit-icon jodit-icon_fontsize"></div>');
}
