import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getVisiblePages } from '../../../../../../selectors/rendering';
import { getIsSolutionsPageVisible } from '../../../../../../selectors/player';
import useSpreadSpecs from './use-spread-specs';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import { getAnnotationLeftPosition } from '../utils';

export default function useVisibleAnnotations({ currentAnnotations, pageWidth, pageHeight }) {
  const visiblePages = useSelector(getVisiblePages);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();
  const { sidebarAnchor } = useContext(UserSettingsContext);

  const visibleAnnotations = useMemo(
    () =>
      currentAnnotations.filter(annotation => {
        if (annotation.left < 0) return true;
        if (annotation.left > pageWidth * visiblePages.length) return true;

        if (isSinglePage) {
          if (isRightPage) return annotation.left + (annotation.width || 0) >= pageWidth;

          return annotation.left < pageWidth || annotation.left > pageWidth * visiblePages.length;
        }

        return true;
      }),
    [currentAnnotations, isSinglePage, isRightPage, visiblePages, pageWidth],
  );

  const getAnnotationLeft = useCallback(
    annotation =>
      getAnnotationLeftPosition(annotation, {
        isSinglePage,
        isRightPage,
        isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      }),
    [isRightPage, isSinglePage, isSolutionsPageVisible, isStandalonePage, pageWidth, pageHeight, sidebarAnchor],
  );

  return { visibleAnnotations, getAnnotationLeft };
}
