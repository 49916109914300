export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
export const LINE_HEIGHTS = [1, 1.15, 1.5, 2, 2.5, 3];
export const SPECIAL_CHARACTERS = [
  '\u03B1', // α
  '\u03B2', // β
  '\u03B3', // γ
  '\u0394', // Δ
  '\u03B8', // θ
  '\u03BB', // λ
  '\u03BC', // μ
  '\u03C0', // π
  '\u03C1', // ρ
  '\u03A3', // Σ
  '\u03C6', // φ
  '\u03A6', // Φ
  '\u03A9', // Ω
  '\u2260', // ≠
  '\u2248', // ≈
  '\u003E', // >
  '\u003C', // <
  '\u2264', // ≤
  '\u2265', // ≥
  '\u0028', // (
  '\u0029', // )
  '\u005B', // [
  '\u005D', // ]
  '\u2212', // −
  '\u00B1', // ±
  '\u002A', // *
  '\u22C5', // ⋅
  '\u00F7', // ÷
  '\u221A', // √
  '\u2220', // ∠
  '\u221F', // ∟
  '\u00B0', // °
  '\u22A5', // ⊥
  '\u2225', // ∥
  '\u2245', // ≅
  '\u221E', // ∞
  '\u222B', // ∫
  '\u2229', // ∩
];
