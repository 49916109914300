/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Dom } from 'jodit/esm/core/dom';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getCurrentTool } from '../../../../../selectors/tools';
import JoditEditor from './jodit-editor';
import { getCurrentSpread } from '../../../../../selectors/rendering';
import tools from '../../../../../enums/tools';
import { getIsSolutionsPageVisible } from '../../../../../selectors/player';
import { UserSettingsContext } from '../../../context/user-settings-context';
import anchorposition from '../../../../../enums/anchorposition';
import useSpreadSpecs from './hooks/use-spread-specs';
import useVisibleAnnotations from './hooks/use-visible-annotations';
import { getEventPositions } from './utils';

const DEFAULT_TEXT = '<p><br></p>';

export default function TextAnnotationLayer({ viewportTransform, pageWidth, pageHeight }) {
  const wrapper = useRef(null);
  const clickRef = useRef(null);

  const currentTool = useSelector(getCurrentTool);
  const spread = useSelector(getCurrentSpread);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const { sidebarAnchor } = useContext(UserSettingsContext);
  const [annotations, setAnnotations] = useState({});
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const currentAnnotations = useMemo(() => annotations[spread] || [], [annotations, spread]);

  const editorRef = useRef(null);

  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();
  const { visibleAnnotations, getAnnotationLeft } = useVisibleAnnotations({ currentAnnotations, pageWidth, pageHeight });

  function handleAnnotationAdd(e) {
    if (e.target !== wrapper.current || currentTool !== tools.TEXT_ANNOTATION || clickRef.current !== wrapper.current) return;

    const id = shortid.generate();
    const { left, top } = getEventPositions(
      {
        top: e.clientY,
        left: e.clientX,
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    const newAnnotation = {
      id,
      text: DEFAULT_TEXT,
      top,
      left,
      resized: false,
      isEdited: false,
    };

    setActiveAnnotation(newAnnotation);
    setAnnotations(prev => ({
      ...prev,
      [spread]: (prev[spread] || []).concat(newAnnotation),
    }));
  }

  function handleAnnotationMove(e, { node }) {
    if (!activeAnnotation) return;

    const { left, top, isOnSolutionsPage } = getEventPositions(
      {
        top: node.getBoundingClientRect().top,
        left: node.getBoundingClientRect().left,
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    if (isOnSolutionsPage) return;

    setAnnotations(prev => ({
      ...prev,
      [spread]: (prev[spread] || []).map(x => (x.id === activeAnnotation.id ? { ...x, left, top } : x)),
    }));

    /**
     * Best effort to have predictable behaviour.
     * When we have a selection range and we move the annotation, the selection range is lost and the editor loses focus.
     */
    const lastTextNode = Dom.last(editorRef.current.editor, n => Dom.isText(n));

    if (lastTextNode) {
      editorRef.current.s.setCursorIn(lastTextNode, false);
    }

    editorRef.current.s.focus();
  }

  const handleAnnotationResize = useCallback(
    (width, height) => {
      setAnnotations(prev => ({
        ...prev,
        [spread]: (prev[spread] || []).map(x => (x.id === activeAnnotation.id ? { ...x, width, height, resized: true } : x)),
      }));
    },
    [activeAnnotation?.id, spread],
  );

  function updateActiveAnnotation(annotation) {
    setAnnotations(prev => ({ ...prev, [spread]: [...prev[spread].filter(x => x.id !== annotation.id), annotation] }));
    setActiveAnnotation(annotation);
  }

  useEffect(() => {
    setActiveAnnotation(null);
  }, [currentTool, spread]);

  useEffect(() => {
    function hasTextContent(annotation) {
      const element = document.createElement('div');
      element.innerHTML = annotation.text;

      return element.textContent.trim() !== '';
    }

    // Clear empty annotations which are not active
    if (spread.length) setAnnotations(prev => ({ ...prev, [spread]: (prev[spread] || []).filter(x => (x.isEdited && hasTextContent(x)) || x.id === activeAnnotation?.id) }));
  }, [activeAnnotation, spread]);

  return (
    <div
      data-testid="text-annotation-layer"
      ref={wrapper}
      className={classNames('text-wrapper', {
        'text-wrapper--interactive': currentTool === tools.TEXT_ANNOTATION,
      })}
      onMouseDown={e => {
        clickRef.current = e.target;
      }}
      onTouchStart={e => {
        clickRef.current = e.target;
      }}
      onClick={handleAnnotationAdd}
    >
      <div
        style={{
          position: 'absolute',
          transform: `matrix(${viewportTransform.join(',')})`,
        }}
      >
        {isSolutionsPageVisible && (
          // This avoids click events going through the solutions page
          <div
            className="solutions-page"
            style={{
              position: 'absolute',
              top: 0,
              left: sidebarAnchor === anchorposition.LEFT ? -pageWidth : pageWidth,
              width: pageWidth,
              height: pageHeight,
            }}
          />
        )}
        {visibleAnnotations.map(annotation => (
          <Draggable
            key={annotation.id}
            onStop={handleAnnotationMove}
            position={{
              y: annotation.top,
              x: getAnnotationLeft(annotation),
            }}
            scale={viewportTransform[0]}
            handle=".handlebar"
          >
            <div className="annotation-wrapper" data-testid={`annotation-${annotation.id}`}>
              {annotation.id === activeAnnotation?.id ? (
                <JoditEditor
                  ref={editorRef}
                  annotation={annotation}
                  onChange={updatedProps =>
                    setAnnotations(prev => ({ ...prev, [spread]: (prev[spread] || []).map(x => (x.id === activeAnnotation.id ? { ...x, isEdited: true, ...updatedProps } : x)) }))
                  }
                  onResize={handleAnnotationResize}
                  scale={viewportTransform[0]}
                />
              ) : (
                <div
                  className="text-annotation-item"
                  style={{
                    height: annotation.resized ? `${annotation.height}px` : 'auto',
                    width: annotation.resized ? `${annotation.width}px` : 'max-content',
                  }}
                  onClick={() => updateActiveAnnotation(annotation)}
                  dangerouslySetInnerHTML={{ __html: annotation.text }}
                />
              )}
            </div>
          </Draggable>
        ))}
      </div>
    </div>
  );
}

TextAnnotationLayer.propTypes = {
  viewportTransform: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
};
