import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { string, func, bool, arrayOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ToolOptions from './options';
import { setColor, setSize } from '../../../../../actions/tools';
import { getTools } from '../../../../../selectors/tools';

const getToolModifiers = (currentTool, lastSelectedTool, tools) => {
  const classList = [];

  if (currentTool === lastSelectedTool) {
    classList.push('tool', `tool--${lastSelectedTool}`);

    if (tools[lastSelectedTool]?.color) {
      classList.push(`tool--${tools[lastSelectedTool].color}`);
    }
  }

  return classList.join(' ');
};

function ToolSet({ isOpen, currentTool, onToolClick, tools, renderWithOptions, tooltip, disabled, menuClassName }) {
  const [lastSelectedTool, setLastSelectedTool] = useState(tools.includes(currentTool) ? currentTool : tools[0]);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const allTools = useSelector(getTools);

  return (
    <div className="pbb-tool-wrapper">
      <button
        type="button"
        onClick={() => onToolClick(lastSelectedTool)}
        title={tools.includes(currentTool) ? t(`${currentTool}Tool.tooltip`) : tooltip}
        className={classNames(
          'pbb-sidebar__button',
          {
            'pbb-sidebar__button--selected': isOpen,
            'pbb-sidebar__button--active': currentTool === lastSelectedTool,
          },
          getToolModifiers(currentTool, lastSelectedTool, allTools),
        )}
        disabled={disabled}
      >
        <i className={classNames('pbb-sidebar__icon', `icon-bb-${lastSelectedTool}`)} />
      </button>
      {isOpen && (
        <div className={classNames("pbb-sidebar__menu", {
          [menuClassName]: Boolean(menuClassName),
        })}>
          <div className="pbb-menu__tools">
            {tools.map(tool => (
              <button
                key={tool}
                type="button"
                title={t(`${tool}Tool.tooltip`)}
                onClick={() => {
                  onToolClick(tool);
                  setLastSelectedTool(tool);
                }}
                className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === tool })}
              >
                <i className={`pbb-sidebar__icon icon-bb-${tool}`} />
              </button>
            ))}
          </div>
          {currentTool && renderWithOptions && (
            <>
              <div className="divider-line" />
              <ToolOptions
                inline
                variant={currentTool}
                onColorClick={color => dispatch(setColor(color))}
                onSizeClick={size => dispatch(setSize(size))}
                currentOptions={allTools[currentTool]}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

ToolSet.propTypes = {
  isOpen: bool,
  currentTool: string,
  onToolClick: func.isRequired,
  tools: arrayOf(string).isRequired,
  renderWithOptions: bool,
  tooltip: string.isRequired,
  disabled: bool,
  menuClassName: string,
};

ToolSet.defaultProps = {
  currentTool: undefined,
  isOpen: false,
  renderWithOptions: true,
  disabled: false,
  menuClassName: undefined,
};

export default ToolSet;
