/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import { normalizeSize } from 'jodit/esm/core/helpers';
import { FONT_SIZES } from '../constants';

class FontSizeSteppers {
  currentFontSizeIndex = -1;

  static requires = ['font'];

  init(editor) {
    this.jodit = editor;

    this.jodit.registerCommand('fontsizeup', this.commandHandler.bind(this)).registerCommand('fontsizedown', this.commandHandler.bind(this));
    this.jodit.events.on('afterInit', () => {
      this.jodit.toolbar.buttons
        .find(x => x.name === 'fontsize')
        .state.on('change.value', (property, prevValue, nextValue) => {
          this.currentFontSizeIndex = FONT_SIZES.indexOf(Number(nextValue));

          this.jodit.toolbar.buttons.forEach(b => b.update());
        });
    });

    Config.prototype.controls.fontsizeup = {
      command: 'fontsizeup',
      isDisabled: () => this.currentFontSizeIndex === FONT_SIZES.length - 1 || this.currentFontSizeIndex === -1,
    };
    Config.prototype.controls.fontsizedown = {
      command: 'fontsizedown',
      isDisabled: () => this.currentFontSizeIndex === 0 || this.currentFontSizeIndex === -1,
    };
  }

  commandHandler(command) {
    switch (command) {
      case 'fontsizeup':
        this.jodit.s.commitStyle({
          attributes: {
            style: {
              fontSize: normalizeSize(FONT_SIZES[this.currentFontSizeIndex + 1], this.jodit.o.defaultFontSizePoints),
            },
          },
        });
        break;
      case 'fontsizedown':
        this.jodit.s.commitStyle({
          attributes: {
            style: {
              fontSize: normalizeSize(FONT_SIZES[this.currentFontSizeIndex - 1], this.jodit.o.defaultFontSizePoints),
            },
          },
        });
        break;
      default: {
        break;
      }
    }

    this.jodit.synchronizeValues();

    return false;
  }

  destruct() {}
}

Jodit.plugins.add('fontsizesteppers', FontSizeSteppers);
Jodit.modules.Icon.set(
  'fontsizeup',
  '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 17L12 8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 11L12 7L8 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>',
);

Jodit.modules.Icon.set(
  'fontsizedown',
  '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7L12 16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8 13L12 17L16 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>',
);
