import { useMemo, useReducer, useCallback, useEffect, useState, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import get from 'get-value';

import throttle from 'lodash/throttle';

import { setColor, setBackgroundColor, setSize } from '../../../../actions/tools';
import { getCurrentTool, getTools } from '../../../../selectors/tools';
import tools from '../../../../enums/tools';
import api from '../../../../services/api';
import LRUCache from '../../../../services/lru-cache';
import { onStoreError } from '../../../../store';
import { getActiveAnnotationSet } from '../../../../selectors/annotationSets';

const ActionTypes = {
  AVAILABLE_ANNOTATIONS_RECEIVED: 'AVAILABLE_ANNOTATIONS_RECEIVED',
  SPREAD_ANNOTATIONS_RECEIVED: 'SPREAD_ANNOTATIONS_RECEIVED',
  ADD_ANNOTATION: 'ADD_ANNOTATION',
  EDIT_ANNOTATION: 'EDIT_ANNOTATION',
  REMOVE_ANNOTATION: 'REMOVE_ANNOTATION',
  REMOVE_BATCH: 'REMOVE_BATCH',
  APPLY_TOOL_OPTIONS_TO_SELECTED_ANNOTATION: 'APPLY_TOOL_OPTIONS_TO_SELECTED_ANNOTATION',
  MOVE_ANNOTATION_TO_TOP: 'MOVE_ANNOTATION_TO_TOP',
};

function filterShapesForVisiblePages(shapes, pages) {
  if (!pages.includes(null)) return shapes;

  if (pages[1] === null) return shapes.filter(shape => shape.left < 0.5 || shape.left >= 1);

  return shapes.filter(shape => shape.left + shape.width >= 0.5 || shape.left < 0);
}

function moveItemToEnd(arr, index) {
  const item = arr[index];

  return arr.filter((x, i) => i !== index).concat(item);
}

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.AVAILABLE_ANNOTATIONS_RECEIVED: {
      const { bySpread, ...rest } = state;
      const { spreads } = action.payload;

      const spreadMap = Object.fromEntries(
        spreads.map(spread => {
          const { [spread]: { data: annotationIds = [] } = {} } = bySpread;
          return [spread, { data: [...annotationIds], fetchAnnotationsNeeded: true, isDirty: false }];
        }),
      );

      return {
        bySpread: {
          ...bySpread,
          ...spreadMap,
        },
        ...rest,
      };
    }
    case ActionTypes.SPREAD_ANNOTATIONS_RECEIVED: {
      const { byId, bySpread } = state;
      const { spread, annotations = [] } = action.payload;
      const { [spread]: { data: annotationIds = [] } = {} } = bySpread;

      const annotationMap = Object.fromEntries(annotations.map(annotation => [annotation.id, annotation]));

      return {
        byId: {
          ...byId,
          ...annotationMap,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            fetchAnnotationsNeeded: undefined,
            data: [...Object.keys(annotationMap), ...annotationIds],
          },
        },
      };
    }
    case ActionTypes.ADD_ANNOTATION: {
      const { byId, bySpread } = state;
      const { spread, newAnnotation } = action.payload;
      const { [spread]: { data: annotationIds = [] } = {} } = bySpread;

      return {
        byId: {
          ...byId,
          [newAnnotation.id]: newAnnotation,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            data: [...annotationIds, newAnnotation.id],
          },
        },
      };
    }
    case ActionTypes.EDIT_ANNOTATION: {
      const { byId, ...rest } = state;
      const { shape } = action.payload;
      return {
        byId: {
          ...byId,
          [shape.id]: {
            ...byId[shape.id],
            ...shape,
          },
        },
        ...rest,
      };
    }
    case ActionTypes.REMOVE_ANNOTATION: {
      const { byId, bySpread } = state;
      const { annotationId } = action.payload;

      const [spread, spreadState] = Object.entries(bySpread).find(([, val]) => val.data && val.data.includes(annotationId));

      return {
        ...state,
        byId: {
          ...byId,
          [annotationId]: undefined,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...spreadState,
            data: (spreadState.data || []).filter(x => x !== annotationId),
          },
        },
      };
    }
    case ActionTypes.REMOVE_BATCH: {
      const { byId, bySpread } = state;
      const { ids, spread } = action.payload;

      const idsToDelete = ids.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: undefined,
        }),
        {},
      );

      return {
        ...state,
        byId: {
          ...byId,
          ...idsToDelete,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            data: bySpread[spread].data.filter(x => !ids.includes(x)),
          },
        },
      };
    }
    case ActionTypes.MOVE_ANNOTATION_TO_TOP: {
      const { bySpread } = state;
      const annotationId = action.payload;

      const [spread, spreadState] = Object.entries(bySpread).find(([, val]) => val.data && val.data.includes(annotationId));
      const index = spreadState.data.indexOf(annotationId);

      const reordered = moveItemToEnd(spreadState.data, index);

      return {
        ...state,
        bySpread: {
          ...bySpread,
          [spread]: {
            ...spreadState,
            data: reordered,
          },
        },
      };
    }
    default:
      throw new Error('unexpected action');
  }
}

function buildOptions(digibook) {
  return { headers: { Authorization: `Bearer ${digibook.systemToken}` } };
}

const TWO_HOURS_IN_MS = 1000 * 60 * 60 * 2;

function useAnnotations(digibook, spreadPages, visiblePages) {
  const currentTool = useSelector(getCurrentTool);
  const currentToolOptions = useSelector(getTools);
  const currentAnnotationOptions = currentToolOptions[tools.ANNOTATION];
  const annotationsEnabled = useSelector(state => get(state, ['featureToggles', 'ANNOTATIONS']));
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const [selectedAnnotationId, setSelectedId] = useState();
  const [dirtySpreads, setDirtySpreads] = useState({});
  const reduxDispatch = useDispatch();
  const signedUrlCache = useRef(new LRUCache(50, TWO_HOURS_IN_MS));

  const currentlyEditing = useRef();

  const [annotationState, dispatch] = useReducer(reducer, {
    byId: {},
    bySpread: {},
  });

  const spread = useMemo(() => {
    if (spreadPages.length > 0) {
      return spreadPages.join('-');
    }
    return '';
  }, [spreadPages]);

  const spreadAnnotations = useMemo(() => {
    const { byId, bySpread } = annotationState;
    const { [spread]: { data: annotationIds = [] } = {} } = bySpread;
    return annotationIds.map(id => byId[id]);
  }, [spread, annotationState]);

  const getCachedSignedUrlForSpread = useCallback(
    async spreadKey => {
      const { current: cache } = signedUrlCache;
      if (!cache.get(spreadKey) && activeAnnotationSet?.id) {
        const options = buildOptions(digibook);
        const { data: signedUrlForSpread } = await api.get(
          `/studio/digibooks/${digibook.id}/annotation-sets/${activeAnnotationSet.id}/text-annotations/${spreadKey}/signed-url`,
          options,
        );
        cache.add(spreadKey, signedUrlForSpread);
        return signedUrlForSpread;
      }

      return cache.get(spreadKey);
    },
    [activeAnnotationSet?.id, digibook],
  );

  useEffect(() => {
    if (activeAnnotationSet?.id) {
      signedUrlCache.current.clear();
    }
  }, [activeAnnotationSet?.id]);

  useEffect(() => {
    async function getSpreadsWithAnnotations() {
      if (!activeAnnotationSet?.id) return;

      try {
        const options = buildOptions(digibook);
        const {
          data: { data: spreads },
        } = await api.get(`/studio/digibooks/${digibook.id}/annotation-sets/${activeAnnotationSet.id}/text-annotations`, options);

        dispatch({
          type: ActionTypes.AVAILABLE_ANNOTATIONS_RECEIVED,
          payload: {
            spreads,
          },
        });
      } catch (e) {
        reduxDispatch(onStoreError(e));
      }
    }

    if (digibook && annotationsEnabled) {
      getSpreadsWithAnnotations();
    }
  }, [digibook, annotationsEnabled, reduxDispatch, activeAnnotationSet?.id]);

  const spreadsBusyFetching = useRef({});

  useEffect(() => {
    async function getAnnotationsForCurrentSpread() {
      try {
        spreadsBusyFetching.current[spread] = true;

        const signedUrlForSpread = await getCachedSignedUrlForSpread(spread);
        const { data: annotations } = await axios.get(signedUrlForSpread);

        dispatch({
          type: ActionTypes.SPREAD_ANNOTATIONS_RECEIVED,
          payload: {
            spread,
            annotations,
          },
        });

        spreadsBusyFetching.current[spread] = false;
      } catch (e) {
        reduxDispatch(onStoreError(e));
      }
    }

    const {
      bySpread: { [spread]: { fetchAnnotationsNeeded } = {} },
    } = annotationState;

    if (fetchAnnotationsNeeded && !spreadsBusyFetching.current[spread]) {
      getAnnotationsForCurrentSpread();
    }
  }, [digibook, annotationState, spread, getCachedSignedUrlForSpread, reduxDispatch]);

  const addAnnotation = useCallback(
    annotation => {
      const newAnnotation = {
        ...annotation,
        color: currentAnnotationOptions.color,
        backgroundColor: currentAnnotationOptions.backgroundColor,
        fontSize: currentAnnotationOptions.size,
      };

      dispatch({
        type: ActionTypes.ADD_ANNOTATION,
        payload: {
          newAnnotation,
          spread,
        },
      });

      setSelectedId(newAnnotation.id);
    },
    [currentAnnotationOptions, spread],
  );

  const removeAnnotation = useCallback(annotationId => {
    dispatch({
      type: ActionTypes.REMOVE_ANNOTATION,
      payload: {
        annotationId,
      },
    });

    currentlyEditing.current = undefined;
  }, []);

  const removeBatch = useCallback(
    ids => {
      dispatch({
        type: ActionTypes.REMOVE_BATCH,
        payload: {
          ids,
          spread,
        },
      });

      setDirtySpreads(dirty => ({ ...dirty, [spread]: true }));
    },
    [spread],
  );

  const editAnnotation = useCallback(changedAnnotation => {
    dispatch({
      type: ActionTypes.EDIT_ANNOTATION,
      payload: {
        shape: changedAnnotation,
      },
    });
  }, []);

  const setSelectedAnnotationId = useCallback(
    id => {
      if (id) {
        const { byId } = annotationState;
        const current = byId[id];

        if (current) {
          /**
           * We use unstable_batchedUpdates to sync the redux store updates with our selected state update.
           * If we don't, we have no proper place to hook into the tooloptions changing for our current annotation.
           */
          unstable_batchedUpdates(() => {
            setSelectedId(id);
            reduxDispatch(setColor(current.color));
            reduxDispatch(setSize(current.fontSize));
            reduxDispatch(setBackgroundColor(current.backgroundColor));
          });
        } else setSelectedId(id);
      } else setSelectedId(id);
    },
    [reduxDispatch, annotationState, setSelectedId],
  );

  useEffect(() => {
    setSelectedId(undefined);
  }, [visiblePages, currentTool]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnnotations = useCallback(
    throttle(async () => {
      const signedUrl = await getCachedSignedUrlForSpread(spread);

      const currentAnnotation = currentlyEditing.current || {};

      const annotationsToSave = spreadAnnotations //
        .map(x => (x.id === currentAnnotation.id ? currentAnnotation : x))
        .filter(x => x.text !== '');

      await axios.put(signedUrl, annotationsToSave, {
        headers: {
          'Content-Type': 'application/json',
          'x-amz-acl': 'bucket-owner-full-control',
        },
      });
    }, 3000),
    [spread, dispatch, spreadAnnotations],
  );

  /**
   * Flush the save when spread changes.
   */
  useEffect(() => () => saveAnnotations.flush(), [saveAnnotations]);

  const selected = annotationState.byId[selectedAnnotationId];

  /**
   * Update annotation state if tool / tool options / selected annotation or visible pages change.
   */
  useEffect(() => {
    if (currentlyEditing.current) {
      if (currentlyEditing.current.text === '') {
        removeAnnotation(currentlyEditing.current.id);
      } else {
        editAnnotation(currentlyEditing.current);
      }
    }
  }, [currentTool, selectedAnnotationId, removeAnnotation, editAnnotation]);

  useEffect(() => {
    currentlyEditing.current = selected ? { ...selected } : undefined;
  }, [selected]);

  useEffect(() => {
    if (currentlyEditing.current) {
      const { color: currentColor, backgroundColor: currentBackgroundColor, fontSize: currentFontSize } = currentlyEditing.current;
      const { color, backgroundColor, size: fontSize } = currentAnnotationOptions;

      if (color !== currentColor || backgroundColor !== currentBackgroundColor || fontSize !== currentFontSize) {
        currentlyEditing.current = {
          ...currentlyEditing.current,
          color,
          backgroundColor,
          fontSize,
        };

        saveAnnotations();
        editAnnotation(currentlyEditing.current);
      }
    }
  }, [currentAnnotationOptions, editAnnotation, saveAnnotations]);

  useEffect(() => {
    if (selectedAnnotationId) {
      dispatch({
        type: ActionTypes.MOVE_ANNOTATION_TO_TOP,
        payload: selectedAnnotationId,
      });
    }
  }, [dispatch, selectedAnnotationId]);

  /**
   * Check if annotationstate has changed since last save. (bulk)
   */
  const isCurrentSpreadDirty = useMemo(() => {
    return !!dirtySpreads[spread];
  }, [dirtySpreads, spread]);

  /**
   * Save when spreadAnnotationState has changed since last save
   */
  useEffect(() => {
    async function save() {
      await saveAnnotations();
    }

    if (isCurrentSpreadDirty) {
      save();
    }
  }, [isCurrentSpreadDirty, saveAnnotations, spreadAnnotations]);

  return [spreadAnnotations, addAnnotation, setSelectedAnnotationId, selectedAnnotationId, saveAnnotations, removeBatch, currentlyEditing];
}

export default function useVisibleAnnotations(digibook, spread, visiblePages) {
  const [spreadAnnotations, addAnnotation, setSelectedAnnotationId, selectedAnnotationId, saveAnnotations, removeBatch, currentlyEditing] = useAnnotations(
    digibook,
    spread,
    visiblePages,
  );

  const visibleSpreadAnnotations = useMemo(() => {
    if (spreadAnnotations.length === 0) return spreadAnnotations;

    return filterShapesForVisiblePages(spreadAnnotations, visiblePages);
  }, [spreadAnnotations, visiblePages]);

  useEffect(() => {
    function removeVisibleAnnotations() {
      if (visibleSpreadAnnotations.length > 0) removeBatch(visibleSpreadAnnotations.map(annotation => annotation.id));
    }

    document.addEventListener('erase-all-clicked', removeVisibleAnnotations);
    return () => {
      document.removeEventListener('erase-all-clicked', removeVisibleAnnotations);
    };
  }, [removeBatch, visibleSpreadAnnotations]);

  return [visibleSpreadAnnotations, addAnnotation, setSelectedAnnotationId, selectedAnnotationId, saveAnnotations, currentlyEditing];
}
